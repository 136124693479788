import Dexie from "dexie";
Dexie.debug = true;

var db = new Dexie("xenex");

db.version(1).stores({
    location_updates: "++id",
    companytypes: "id,name_upper",
    companies: "id,updated_at,name_upper,city_upper,last_visit,*name_search",
    products:
        "id,name_upper,barcode,productgroup_id,productsubgroup_id,*name_search,[productgroup_id+productsubgroup_id]",
    productgroups: "id,parent_id,name_upper",
    productImages: "id",
    visits: "id,companies_id",
    orders: "id,companies_id",
    newVisits: "++id",
    newOrders: "++id",
    specialprices: "id,[companies_id+products_id],brand_id,[companies_id+brand_id]"
});

db.version(2).stores({
    location_updates: "++id",
    companytypes: "id,name_upper",
    companies: "id,updated_at,name_upper,city_upper,last_visit,*name_search",
    products:
        "id,name_upper,barcode,productgroup_id,productsubgroup_id,*name_search,[productgroup_id+productsubgroup_id],is_light",
    productgroups: "id,parent_id,name_upper",
    productImages: "id",
    visits: "id,companies_id",
    orders: "id,companies_id",
    newVisits: "++id",
    newOrders: "++id",
    specialprices: "id,[companies_id+products_id],brand_id,[companies_id+brand_id]"
});

db.version(3).stores({
    location_updates: "++id",
    companytypes: "id,name_upper",
    companies: "id,updated_at,name_upper,city_upper,last_visit,*name_search",
    products:
        "id,name_upper,code,barcode,productgroup_id,productsubgroup_id,*name_search,[productgroup_id+productsubgroup_id+is_light]",
    productgroups: "id,parent_id,name_upper",
    productImages: "id",
    visits: "id,companies_id",
    orders: "id,companies_id",
    newVisits: "++id",
    newOrders: "++id",
    specialprices: "id,[companies_id+products_id],brand_id,[companies_id+brand_id]"
});

db.version(4).stores({
    location_updates: "++id",
    companytypes: "id,name_upper",
    companies: "id,updated_at,name_upper,city_upper,last_visit,*name_search",
    products:
        "id,name_upper,code,barcode,productgroup_id,productsubgroup_id,*name_search,[productgroup_id+productsubgroup_id+is_light]",
    productgroups: "id,parent_id,name_upper",
    productImages: "id",
    topproducts: "[products_id+companies_id],companies_id",
    visits: "id,companies_id",
    orders: "id,companies_id",
    newVisits: "++id",
    newOrders: "++id",
    specialprices: "id,[companies_id+products_id],brand_id,[companies_id+brand_id]"
});

export { db };
